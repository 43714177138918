import React, { useState } from "react";
import "./style.css";
import OfferModal from "./OfferModal";

const AdminOfferView = () => {


  return (
    <div>
      <OfferModal   />
    </div>
  );
};

export default AdminOfferView;
