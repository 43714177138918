// HeaderSlider.jsx

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImage from "../../images/slider-image-1.jpg";
import sliderImage2 from "../../images/slider-image-2.jpeg";
import sliderImage3 from "../../images/slider-image-3.jpeg";
import "../../fonts/font-awesome/css/font-awesome.css";
import welcomeVideo from "../../images/sdg3/welcomeVideo.mp4"
import "./style.css"; // Import your CSS file
import {Link} from "react-router-dom";
import {Card, CardMedia} from "@mui/material";

const HeaderSlider = () => {
    return (
        <div className='containerSlider'>
            <video autoPlay muted loop style={{width: "100%"}}>
                <source src={welcomeVideo}/>
            </video>
        </div>
    );
};

export default HeaderSlider;
